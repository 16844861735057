<template>
  <div class="home">
    <div class="Navigation_Home">
      <div class="Navigation_Home_content fixation Navigation_Pc">
        <div class="Navigation_Home_content_img">
          <img src="../../assets/img/home/logo.png" alt="" />
        </div>
        <div class="Navigation_Home_content_menu">
          <router-link to="/" class="link">网站首页</router-link>
          <router-link to="/product" class="link">产品系列</router-link>
          <router-link to="/journalism" class="link">{{
            $globalVar.menu3
          }}</router-link>
          <router-link to="/dialogue" class="link">用户留言</router-link>
          <router-link to="/about" class="link">关于我们</router-link>
        </div>
      </div>
      <div class="Navigation_Home_info f">
        <el-button plain class="btn" @click="goKnow">了解更多</el-button>
      </div>
    </div>
    <div class="Navigation_Home_H5">
      <Navigation />
      <div class="Navigation_Home_H5_content">
        <div class="Navigation_Home_info f">
          <el-button plain class="btn" @click="goKnow">了解更多</el-button>
        </div>
      </div>
    </div>
    <!-- 产品系列 -->
    <div class="Product">
      <div class="title f">
        <h1>产品系列</h1>
        <p>PRODUCT CLASSIFICATION</p>
      </div>
      <div class="Product_content fixation">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide Product_content_chunk"
              v-for="(item, index) in product"
              :key="item.id"
              @click="goto('/product', item.anchorId)"
            >
              <img :src="item.img" alt="" />
              <div>
                <h2>{{ item.title }}</h2>
                <p>{{ item.info }}</p>
                <button style="color: #cc9966">
                  {{ item.titleE }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="Product_content_H5">
        <div class="swiper-container_h5">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide Product_content_chunk"
              v-for="(item, index) in product"
              :key="item.id"
              @click="goto('/product', item.anchorId)"
            >
              <img :src="item.img" alt="" />
              <div>
                <h2>{{ item.title }}</h2>
                <p>{{ item.info }}</p>
                <el-button>{{ item.titleE }}</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 大龙 -->
    <div class="Product2">
      <div class="title f">
        <h1>专业团队</h1>
        <p>SPECIALITY TEAM</p>
      </div>
      <!-- PC端 -->
      <div class="team_PC show_pc" v-if="isPC">
        <!-- 轮播图盒子 -->
        <div class="show_box_left">
          <div class="swiper_container_team swiper-no-swiping">
            <!-- 轮播图滚动区域的盒子 -->
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in teamData"
                :key="item.id"
              >
                <img :src="item.img" alt="" />
              </div>
            </div>
            <div class="btn_box">
              <img
                class="btn prev"
                src="@/assets/img/home/left.png"
                @click="btnClick('prev')"
              />
              <img
                class="btn next"
                @click="btnClick('next')"
                src="@/assets/img/home/right.png"
              />
            </div>
          </div>
        </div>
        <!-- 右面 -->
        <div class="show_box_right  swiper_container_team_right swiper-no-swiping">
          <!-- <div class="swiper_container_team_right"> -->
            <!-- 轮播图滚动区域的盒子 -->
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in teamDataRight"
                :key="item.id"
              >
                <img :src="item.img" alt="" />
              </div>
            </div>
          <!-- </div> -->
        </div>

        <!-- <img class="show_box_right" src="@/assets/img/home/dalong_right.png" /> -->
      </div>

      <!-- 手机端 -->
      <div class="Product_content_H5">
        <div class="swiper-container_h5">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide Product_content_chunk"
              v-for="(item, index) in teamDataMobel"
              :key="item.id"
              @click="goto('/product', item.anchorId)"
            >
              <img :src="item.img" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!-- 手机端 end -->
    </div>

    <!-- 活动趣闻 -->
    <div class="Journalism">
      <div class="title z">
        <h1>{{ $globalVar.menu3 }}</h1>
        <p>{{ $globalVar.menu3_desc1 }} {{ $globalVar.menu3_desc2 }}</p>
      </div>
      <div class="Journalism_content fixation">
        <el-carousel class="carousel">
          <el-carousel-item v-for="item in message" :key="item.id">
            <div class="Journalism_content_chunk">
              <div class="Journalism_content_chunk_left">
                <img :src="item.newsImage" alt="" />
              </div>
              <div class="Journalism_content_chunk_right z">
                <h3 class="z">
                  {{ item.newsTitle }}
                </h3>
                <p>
                  {{ item.newsDesc }}
                </p>
                <el-button class="btn" @click="onGoDetails(item.id)"
                  >查看详情</el-button
                >
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <div class="carousel_h5">
          <div
            class="carousel_h5_Swiper"
            v-for="(item, index) in message"
            :key="item.id"
          >
            <div
              class="carousel_h5_content_chunk"
              @click="onGoDetails(item.id)"
            >
              <img :src="item.newsImage" alt="" />
              <div>
                <h3>{{ item.newsTitle }}</h3>
                <p>{{ item.newsDate }}</p>
              </div>
              <!-- <el-button class="btn">查看详情</el-button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="About fixation">
      <div class="AboutPc">
        <div class="About_left">
          <div class="title z">
            <h1>关于我们</h1>
            <p>ABOUT US</p>
          </div>
          <div>
            <p class="About_left_info z">
              <!-- 北京佰达康科技有限公司面向全中国人提供基因检测服务，开展基因生命数据分析业务。并依托基因大数据平台，耗时数年倾情打造了“我和你之间”系列基因大数据应用IP。在今后的时间里，佰达康科技将进行更多尝试，努力让基因文化融入更多人日常生活，用温暖且“接地气”的方式传播文化、焕发IP活力之余，为品牌持续赋能。 -->
              {{ $globalVar.about_desc }}
            </p>
            <div class="About_left_info1 z">
              <img src="../../assets/img/home/icon2.png" alt="" />
              <p>基因大数据的专业解读为生命和爱赋予新的内涵</p>
            </div>
            <div class="About_left_info1 z">
              <img src="../../assets/img/home/icon1.png" alt="" />
              <p>资深情感导师定制完成彼此更了解相处更简单</p>
            </div>
          </div>
        </div>
        <div class="About_right">
          <img src="../../assets/img/home/AboutUs.jpg" alt="" />
        </div>
      </div>
      <div class="AboutH5">
        <div class="title z">
          <h1>关于我们</h1>
          <p>ABOUT US</p>
        </div>
        <div class="About_top">
          <div></div>
          <img src="../../assets/img/home/AboutUs.jpg" alt="" />
        </div>
        <div class="About_bottom">
          <p class="z" style="text-indent: 2em;">
            <!-- 北京佰达康科技有限公司面向全中国人提供基因检测服务，开展基因生命数据分析业务。并依托基因大数据平台，耗时数年倾情打造了“我和你之间”系列基因大数据应用IP。在今后的时间里，佰达康科技将进行更多尝试，努力让基因文化融入更多人日常生活，用温暖且“接地气”的方式传播文化、焕发IP活力之余，为品牌持续赋能。 -->
            {{ $globalVar.about_desc }}
          </p>
          <div class="About_botton_info">
            <div class="z">
              <img src="../../assets/img/home/icon2.png" alt="" />
              <p>基因大数据的专业解读为生命和爱赋予新的内涵</p>
            </div>
            <div class="z">
              <img src="../../assets/img/home/icon1.png" alt="" />
              <p>资深情感导师定制完成彼此更了解相处更简单</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Dialogue">
      <div class="Dialogue_content fixation">
        <div class="title f">
          <h1>用户留言</h1>
          <p>NEWS INFORMATION</p>
        </div>
        <p class="z Dialogue_content_info">
          请填写您的需要，我们会尽快给您回复！
        </p>
        <div class="Dialogue_content_ipt">
          <div>
            <el-input v-model="name">
              <template slot="prepend">姓名：</template>
            </el-input>
          </div>
          <div>
            <el-input v-model="phone">
              <template slot="prepend">电话：</template>
            </el-input>
          </div>
          <div>
            <el-input v-model="verify">
              <template slot="prepend">验证码：</template>
              <template slot="append"><refreshCode ref="refresh" /></template>
            </el-input>
          </div>
        </div>
        <div class="Dialogue_content_txt">
          <el-input
            type="textarea"
            :autosize="{ minRows: 6, maxRows: 4 }"
            placeholder="请输入您的需求"
            v-model="textarea1"
          >
          </el-input>
        </div>
        <el-button class="submit" @click="gosubmit">提交</el-button>
      </div>
    </div>
    <div class="relation">
      <div class="title z">
        <h1>联系我们</h1>
        <p>CONTACT US</p>
      </div>
      <div class="relation_content fixation">
        <div class="relation_content_lift">
          <div class="relation_content_chunk">
            <div class="relation_content_chunk_circle">
              <img src="../../assets/img/home/icon36.png" alt="" />
            </div>
            <div class="relation_content_chunk_info">
              <h4>客服电话：</h4>
              <p>13601124217</p>
            </div>
          </div>
          <div class="relation_content_chunk">
            <div class="relation_content_chunk_circle">
              <img src="../../assets/img/home/icon33.png" alt="" />
            </div>
            <div class="relation_content_chunk_info">
              <h4>微信公众号：</h4>
              <p>EM DNA UOY我和你之间</p>
            </div>
          </div>
          <div class="relation_content_chunk">
            <div class="relation_content_chunk_circle">
              <img src="../../assets/img/home/icon35.png" alt="" />
            </div>
            <div class="relation_content_chunk_info">
              <h4>客服时间：</h4>
              <p>工作日 8:30-17:30</p>
            </div>
          </div>
          <div class="relation_content_chunk">
            <div class="relation_content_chunk_circle">
              <img src="../../assets/img/home/site.png" alt="" />
            </div>
            <div class="relation_content_chunk_info">
              <h4>地址：</h4>
              <p>北京市大兴区荣华南路9号院亦城科技中心2号楼303</p>
            </div>
          </div>
        </div>
        <img
          src="../../assets/img/home/we.png"
          alt="EM DNA UOY我和你之间"
          class="QRCode"
        />
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /srcscript
import Swiper from "swiper";

import Navigation from "../../components/Navigation.vue";
import { Message } from "element-ui";
import refreshCode from "../../components/refreshCode.vue";
export default {
  name: "Home",
  components: { refreshCode, Navigation },
  data() {
    return {
      isPC: true,
      teamSwiper: null,
      teamSwiper_right: null,
      name: "",
      phone: "",
      verify: "",
      textarea1: "",
      anchorFage: false, //解决锚点跳转和导航跳转冲突问题
      message: [
        // {
        //   id: 1,
        //   img: require("@/assets/img/home/新闻资讯.jpg"),
        //   title: "圆方基因 引领基因科技的普惠应用",
        //   date: "2022-02-6 22:22:22",
        //   info: "企业借助大数据分析、机器学习等前沿技术来高效计算分析海量的生命数据，以获得精准的分析结果。",
        // },
        // {
        //   id: 2,
        //   img: require("@/assets/img/home/新闻资讯2.jpg"),
        //   title:
        //     "消费级基因检测未来应用场景广阔,文创产业是发展新经济形式的有效途径。",
        //   date: "2022-02-6 22:22:22",
        //   info: "企业借助大数据分析、机器学习等前沿技术来高效计算分析海量的生命数据，以获得精准的分析结果。",
        // },
        // {
        //   id: 3,
        //   img: require("@/assets/img/home/新闻资讯3.jpg"),
        //   title:
        //     "消费级基因检测未来应用场景广阔,文创产业是发展新经济形式的有效途径。",
        //   date: "2022-02-6 22:22:22",
        //   info: "企业借助大数据分析、机器学习等前沿技术来高效计算分析海量的生命数据，以获得精准的分析结果。",
        // },
      ],
      imgShow: null,
      // 产品系列
      product: [
        {
          id: 1,
          img: require("@/assets/img/home/gougou.png"),
          title: this.$globalVar.menu2_c_1, //"宠物系列",
          titleE: "查看详情",
          info: "你了解沉默的它吗？每个狗狗的性格都是特别的，在生命形成之初，它便有了独特的性格，用基因测序的方式了解它，能让你更好的饲养它。",
          anchorId: "loveSeries",
        },
        {
          id: 2,
          img: require("@/assets/img/home/LoveSeries.jpg"),
          title: this.$globalVar.menu2_c_2, //"爱情系列",
          titleE: "查看详情",
          info: "爱上他，了解他。从陌生人到亲密爱人，这期间是慢慢熟悉的过程，如果有一种方式能让你了解最原始的他，你会感兴趣吗？",
          anchorId: "DesignConcept",
        },
        {
          id: 3,
          img: require("@/assets/img/home/FriendshipSeries.jpg"),
          title: "友情系列",
          titleE: "查看详情",
          info: "TA是这个世界上最了解自己的人，了解自己的软弱与黑暗，友情，随着岁月的流逝越发浓厚、真挚；是别人不理解我们时的港湾与依靠，TA不一定可以时时刻刻跑过来陪你，但你们一定心有灵犀。",
          anchorId: "affection",
        },
      ],
      // 专业团队
      teamData: [
        {
          id: 1,
          img: require("@/assets/img/home/dalong.svg"),
          title: this.$globalVar.menu2_c_1, //"爱情系列",
          titleE: "查看详情",
          info: "爱情是最有魔力的一种情感，也诗人永久吟诵的主题，我们对彼此朝思暮想，甚至不惜付出生命，只求和TA在一起,它能让一个枯朽的人，容光焕发,能让一个足不出户的人，仗剑天涯。",
          anchorId: "loveSeries",
        },
        {
          id: 2,
          img: require("@/assets/img/home/dalong2.svg"),
          title: this.$globalVar.menu2_c_2, //"亲情系列",
          titleE: "查看详情",
          info: "有血缘关系的整体，是剪不断的情，是扯不开的爱，它包容你的任何任性与不成熟；亲情像一个随时停靠在你身旁的加油站，能让你获得能量继续前行。",
          anchorId: "DesignConcept",
        },
        {
          id: 3,
          img: require("@/assets/img/home/dalong3.svg"),
          title: "友情系列",
          titleE: "查看详情",
          info: "TA是这个世界上最了解自己的人，了解自己的软弱与黑暗，友情，随着岁月的流逝越发浓厚、真挚；是别人不理解我们时的港湾与依靠，TA不一定可以时时刻刻跑过来陪你，但你们一定心有灵犀。",
          anchorId: "affection",
        },
      ],
      teamDataRight: [
        {
          img: require("@/assets/img/home/right2.png"),
        },
        {
          img: require("@/assets/img/home/right3.png"),
        },
        {
          img: require("@/assets/img/home/right1.png"),
        },
      ],
      // 专业团队（H5）
      teamDataMobel: [
        {
          img: require("@/assets/img/home/h5/dl1.svg"),
        },
        {
          img: require("@/assets/img/home/h5/dl2.svg"),
        },
        {
          img: require("@/assets/img/home/h5/dl3.svg"),
        },
      ],
    };
  },
  methods: {
    checkDeviceType() {
      if (document.documentElement.clientWidth >= 1024) {
        this.isPC = true;
      } else {
        this.isPC = false;
      }
    },
    // 轮播图点击
    btnClick(instruct) {
      console.log("btnClick", instruct);
      if (instruct.includes("prev")) {
        this.teamSwiper.slidePrev();
        this.teamSwiper_right.slidePrev();
      } else {
        this.teamSwiper.slideNext();
        this.teamSwiper_right.slideNext();
      }
    },
    //科普趣味
    journalismHttp() {
      this.$api
        .newsList({ begin: 1, pageNum: 3, newsState: 0 })
        .then((res) => {
          if (res.code == 0) {
            this.message = res.data;
          } else {
            console.log("获取信息失败，请稍后再试！");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //详情跳转
    onGoDetails(id) {
      this.$router.push({ path: `/newDetails?id=${id}` });
    },
    //了解更多
    goKnow() {
      this.$router.push("/product");
    },
    //用户留言
    gosubmit() {
      let nameReg = /\S+/; //姓名不能为空
      var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/; //手机号
      if (!nameReg.test(this.name)) {
        Message({
          message: "警告哦，姓名不能为空",
          type: "warning",
        });
      } else if (!myreg.test(this.phone)) {
        Message({
          message: "警告哦，手机号格式错误",
          type: "warning",
        });
      } else if (this.verify !== this.$refs.refresh.identifyCode) {
        Message({
          message: "警告哦，验证码错误",
          type: "warning",
        });
      } else if (!nameReg.test(this.textarea1)) {
        Message({
          message: "警告哦，需求不能为空",
          type: "warning",
        });
      } else {
        //网络请求
        this.$api
          .getGuestBook({
            userName: this.name,
            phone: this.phone,
            message: this.textarea1,
          })
          .then((res) => {
            // console.log(res);
            if (res.code === 0) {
              Message({
                message: "您的需求提交成功",
                type: "success",
              });
              this.name = "";
              this.phone = "";
              this.textarea1 = "";
              this.verify = "";
            } else {
              Message({
                message: res.msg,
                type: "success",
              });
            }
          })
          .catch((err) => {
            Message.error("网络错误请稍后重试");
          });
      }
    },
    //产品分类锚点跳转
    //跳转方法
    goto(page, selectID) {
      // console.log(this.$route.path, "this.$route.path");

      if (page == this.$route.path) {
        //如果当前已经在这个页面不跳转 直接去
        let toElement = document.getElementById(selectID);
        toElement.scrollIntoView(true);
      } else {
        //否则跳转路由
        localStorage.setItem("toId", selectID);
        this.$router.push({ path: page });
        this.anchorFage = true;
        this.$bus.$emit("sendMsg", this.anchorFage);
      }
    },
    initSwiper() {
      this.journalismHttp();
      new Swiper(".swiper-container", {
        direction: "horizontal",
        loop: true,
        autoplay: true,
        slidesPerView: "auto",
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });

      new Swiper(".swiper-container_h5", {
        direction: "horizontal",
        loop: true,
        autoplay: true,
        slidesPerView: "auto",
        // spaceBetween: 5,
        centeredSlides: true,
        loopAdditionalSlides: 3,
        slidesOffsetBefore: 0.5,
        slidesOffsetAfter: 0.5,
      });

      // 专业团队
      this.teamSwiper = new Swiper(".swiper_container_team", {
        loop: true,
        autoplay: true,
      });
      // 专业团队(right)
      this.teamSwiper_right = new Swiper(".swiper_container_team_right", {
        loop: true,
        autoplay: true,
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initSwiper();

      this.checkDeviceType();
    });
    window.addEventListener("resize", () => {
      this.checkDeviceType();
    });
  },
};
</script>
<style lang="less" scoped>
.home {
  width: 100%;
  .active {
    color: #cc9966 !important;
  }
  //   导航菜单
  .Navigation_Home {
    background-image: url("../../assets/img/home/HomePageBackground.jpg");
    height: 5rem;
    width: 100%;
    text-align: center;
    background-size: 100% 100%;
    position: relative;
    .Navigation_Home_content {
      margin: 0 auto;
      display: flex;
      height: 110px;
      line-height: 110px;
      .Navigation_Home_content_img {
        width: 14%;
        height: 100%;
        img {
          width: 112px;
          height: 50px;
        }
      }
      .Navigation_Home_content_menu {
        text-align: center;
        flex: 1;
        font-size: 25px;
        a {
          font-family: cursive;
          color: #fff;
          padding: 0 2.83%;
        }
        .link:hover {
          color: #cc9966 !important;
        }
      }
    }
  }
  .Navigation_Home_H5 {
    display: none;
  }
  //   导航介绍
  .Navigation_Home_info {
    color: #fff;
    position: absolute;
    bottom: 20%;
    left: 50%;
    margin-left: -0.6rem;
    .btn {
      width: 1.2rem;
      height: 0.32rem;
      border: 2px solid #ffffff;
      background-color: transparent;
      font-size: 0.13rem;
      color: #fff;
    }
    .btn:hover {
      border: 2px solid #cc9966;
      background-color: #cc9966;
    }
  }
  //标题
  .title {
    position: relative;

    h1 {
      font-size: 32px;
      font-weight: bold;
      color: #000000;
      margin-bottom: 0.1041666rem;
    }
    p {
      font-size: 18px;
      color: #000000;
      opacity: 0.5;
      letter-spacing: 1px;
    }
  }
  //产品分类
  .Product {
    padding-top: 0.6rem;
    text-align: center;
    .Product_content {
      margin: 0 auto;
      margin-top: 0.37rem;
      display: flex;

      .Product_content_chunk {
        width: 2.6rem;
        background: #fafafa;
        text-align: left;
        margin-right: 0.1rem;
        cursor: pointer;
        img {
          width: 100%;
          height: 1.8rem;
        }
        div {
          padding: 0.2rem 0.25rem;
          h2 {
            color: #2d2d2d;
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 0.14rem;
          }
          p {
            font-size: 18px;
            color: #888888;
            font-weight: 500;
            line-height: 0.14rem;
            text-align: justify;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3; //需要控制的文本行数
            overflow: hidden;
            margin-bottom: 0.14rem;
            height: 0.41rem;
          }
          button {
            background-color: transparent;
            font-size: 0.1rem;
            cursor: pointer;
          }
          .ProductTitle {
            color: #cc9966;
          }
        }
      }
    }
    .Product_content_H5 {
      display: none;
    }
  }
  //新闻资讯
  .Journalism {
    padding-top: 0.6rem;
    text-align: center;
    .Journalism_content {
      margin: 0 auto;
      margin-top: 0.37rem;
      .carousel {
        display: block;
        /deep/ .el-carousel__button {
          background-color: #fff;
          width: 20px;
        }
      }
      .carousel_h5 {
        display: none;
      }
      /deep/ .carousel {
        height: 3rem;
        .el-carousel__item {
          height: 3rem;
        }
      }
      /deep/ .el-carousel__container {
        height: 3rem;
      }
      /deep/ .el-carousel__indicators {
        background-color: rgba(31, 45, 61, 0.2);
        border-radius: 0.2rem;
        padding: 0 0.05rem;
        margin-bottom: 0.1rem;
      }
      .Journalism_content_chunk {
        overflow: hidden;
        .Journalism_content_chunk_left {
          float: left;
          width: 48%;
          height: 3rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .Journalism_content_chunk_right {
          float: left;
          width: 52%;
          padding: 0.55rem;
          height: 3rem;
          box-sizing: border-box;
          background-color: #f7f7f9;
          text-align: justify;
          h3 {
            font-size: 24px;
            font-weight: 900;
            color: #2d2d2d;
            // height: 0.4rem;
            line-height: 0.2rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2; //需要控制的文本行数
            overflow: hidden;
            margin-bottom: 0.15rem;
          }
          p {
            font-size: 18px;
            color: #333333;
            line-height: 0.2rem;
            height: 0.8rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4; //需要控制的文本行数
            overflow: hidden;
          }
          .btn {
            width: 0.6rem;
            height: 0.24rem;
            background-color: #cc9966;
            font-size: 0.1rem;
            color: #ffffff;
            margin-top: 0.2rem;
          }
          .btn:hover {
            background-color: #0084ff;
          }
        }
      }
    }
  }
  //关于我们
  .About {
    margin: 0.6rem auto;
    display: flex;
    .AboutPc {
      display: flex;
    }
    .AboutH5 {
      display: none;
    }
    .About_left {
      width: 49%;
      margin-right: 2%;
      box-sizing: border-box;
      padding-right: 2.4%;
      padding-top: 0.625rem;
      .About_left_info {
        font-size: 18px;
        font-weight: 400;
        color: #222222;
        height: 0.8rem;
        margin: 0.16rem 0;
        text-align: justify;
        line-height: 0.16rem;
      }
      .About_left_info1 {
        display: flex;
        margin: 0.1rem 0;
        img {
          width: 35px;
          height: 40px;
        }
        p {
          padding: 0 20px;
          line-height: 0.22rem;
          font-size: 0.1rem;
          color: #444;
        }
      }
    }
    .About_right {
      width: 33%;
      height: 3rem;
      background-color: #cc9966;
      position: relative;
      img {
        position: absolute;
        top: 0.5rem;
        height: 2rem;
        left: 17%;
        width: 132%;
      }
    }
  }
  //用户留言
  .Dialogue {
    width: 100%;
    background-image: url("../../assets/img/home/UserMessageBackground.jpg");
    overflow: hidden;
    .Dialogue_content {
      margin: 0.44rem auto;
      background-color: #fff;
      border-radius: 2px;
      box-sizing: border-box;
      padding: 0.41rem 0.574rem;
      .Dialogue_content_info {
        font-size: 24px;
        color: #666666;
        margin-top: 0.18rem;
        margin-bottom: 0.15rem;
      }
      .Dialogue_content_ipt {
        display: flex;
        div {
          background: #fff;
          border-radius: 5px;
          font-size: 18px;
          color: #222222;
          height: 0.3021rem;
          /deep/ .el-input-group__prepend {
            padding-right: 0;
          }
          /deep/ .el-input {
            input {
              height: 0.3021rem;
              background: #f2f2f2;
              border: 0px;
              padding: 0;
            }
            .el-input-group__prepend {
              background: #f2f2f2;
              border: 0px;
            }
            .el-input-group__append {
              background: #fff;
              border: 0px;
            }
          }
        }
        div:nth-child(2) {
          margin-left: 0.17rem;
          margin-right: 0.17rem;
        }
      }
      .Dialogue_content_txt {
        margin-top: 0.17rem;
        /deep/ .el-textarea {
          textarea {
            background: #f7f7f7;
            border: 0px;
            resize: none;
            padding: 0.1rem;
            font-size: 18px;
          }
        }
      }
      .submit {
        width: 0.6rem;
        height: 0.24rem;
        background-color: #cc9966;
        font-size: 0.1rem;
        color: #ffffff;
        margin-top: 0.2rem;
        padding: 0;
      }
      /deep/ .el-button--default {
        padding: 0 !important;
      }
      .submit:hover {
        background-color: #cc9966;
      }
    }
  }
  //联系我们
  .relation {
    text-align: center;
    padding-top: 0.57rem;
    background-image: url("../../assets/img/home/ContactUsBackground.jpg");
    width: 100%;
    box-sizing: border-box;
    .relation_content {
      margin: 0.47rem auto 0;
      padding-bottom: 0.47rem;
      display: flex;
      .relation_content_lift {
        display: flex;
        flex-wrap: wrap;
        width: 80%;
        .relation_content_chunk {
          width: 50%;
          height: 0.625rem;
          display: flex;
          margin-bottom: 0.1rem;
          .relation_content_chunk_circle {
            width: 0.5rem;
            height: 0.5rem;
            background-color: #cc9966;
            border-radius: 50%;
            line-height: 0.5rem;
            img {
              width: 0.25rem;
              height: 0.25rem;
            }
          }
          .relation_content_chunk_info {
            flex: 1;
            padding: 0.05rem 0.1rem;
            text-align: left;
            h4 {
              font-size: 24px;
              color: #333333;
              padding: 0.05rem 0;
              font-weight: 900;
            }
            p {
              font-size: 18px;
              color: #333333;
              font-weight: 400;
            }
          }
        }
      }
      .QRCode {
        width: 1.1rem;
        height: 1.1rem;
        margin-top: 0.05rem;
        margin-left: 0.2rem;
      }
    }
  }
  @media all and(min-width: 1900px) {
    .fixation {
      width: 65%;
    }
  }
  @media all and(max-width: 1900px) {
    .fixation {
      width: 65%;
    }
  }
  @media all and(max-width: 1800px) {
    .fixation {
      width: 68%;
    }
  }
  @media all and(max-width: 1700px) {
    .fixation {
      width: 72%;
    }
  }
  @media all and(max-width: 1600px) {
    .fixation {
      width: 76%;
    }
  }
  @media all and(max-width: 1500px) {
    .fixation {
      width: 80%;
    }
  }
  @media all and(max-width: 1400px) {
    .fixation {
      width: 84%;
    }
  }
  @media all and(max-width: 1300px) {
    .fixation {
      width: 88%;
    }
  }
  @media all and(max-width: 1200px) {
    .fixation {
      width: 92%;
    }
  }
  // and (max-width: 1680px)
  @media all and (min-width: 1041px) {
    // PC端显示
    .show_pc {
      display: block !important;
    }
    //   导航菜单
    .Navigation_Home {
      background-image: url("../../assets/img/home/HomePageBackground.jpg");
      height: 5rem;
      width: 100%;
      text-align: center;
      background-size: 100% 100%;
      position: relative;
      .Navigation_Home_content {
        margin: 0 auto;
        display: flex;
        height: 110px;
        line-height: 110px;
        .Navigation_Home_content_img {
          width: 14%;
          height: 100%;
          img {
            width: 112px;
            height: 50px;
          }
        }
        .Navigation_Home_content_menu {
          text-align: center;
          flex: 1;
          font-size: 25px;
          a {
            font-family: cursive;
            color: #fff;
            padding: 0 2.83%;
          }
          .link:hover {
            color: #cc9966 !important;
          }
        }
      }
    }
    //   导航介绍
    .Navigation_Home_info {
      color: #fff;
      position: absolute;
      bottom: 20%;
      left: 50%;
      margin-left: -0.6rem;
      .btn {
        width: 1.2rem;
        height: 0.32rem;
        border: 2px solid #ffffff;
        background-color: transparent;
        font-size: 0.13rem;
        color: #fff;
      }
      .btn:hover {
        border: 2px solid #cc9966;
        background-color: #57aeff4b;
      }
    }
    //标题
    .title {
      position: relative;

      h1 {
        font-size: 30px;
        font-weight: bold;
        color: #000000;
        margin-bottom: 0.1041666rem;
      }
      p {
        font-size: 18px;
        color: #000000;
        opacity: 0.5;
        letter-spacing: 1px;
      }
    }
    //产品分类
    .Product {
      padding-top: 0.6rem;
      text-align: center;
      .Product_content {
        margin: 0 auto;
        margin-top: 0.37rem;
        display: flex;
        .Product_content_chunk {
          width: 2.6rem;
          background: #fafafa;
          text-align: left;
          margin-right: 0.1rem;
          cursor: pointer;
          img {
            width: 100%;
            height: 1.8rem;
          }
          div {
            padding: 0.3rem 0.4rem;
            h2 {
              color: #2d2d2d;
              font-size: 18px;
              font-weight: bold;
              margin-bottom: 0.14rem;
            }
            p {
              font-size: 14px;
              color: #888888;
              font-weight: 500;
              line-height: 0.14rem;
              text-align: justify;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3; //需要控制的文本行数
              overflow: hidden;
              margin-bottom: 0.14rem;
              height: 0.41rem;
            }
            button {
              background-color: transparent;
              font-size: 0.1rem;
              cursor: pointer;
            }
            .ProductTitle {
              color: #cc9966;
            }
          }
        }
      }
      .Product_content_H5 {
        display: none;
      }
    }

    // 专业团队
    .Product2 {
      margin-top: 100px;
      // background-color: #ccc;
      background-image: url("../../assets/img/home/bg_yellow.png");
      background-repeat: no-repeat;
      padding-top: 0.6rem;
      text-align: center;
      // 默认不显示 根据媒体显示
      .team_PC {
        display: none;
        margin-top: 0.37rem;
        position: relative;
        display: flex;
        // 左面
        .show_box_left {
          position: relative;
          width: 70%;
          .swiper_container_team {
            overflow: hidden;
            width: 100%;
            img {
              width: 100%;
              height: 100%;
            }
            .btn_box {
              position: absolute;
              right: -50px;
              bottom: 0.5rem;
              border: 1px solid #666;
              width: 0.4rem;
              height: 0.2rem;
              z-index: 999;
              .btn {
                width: 50%;
                cursor: pointer;
              }
            }
          }
        }
        // 右面
        .show_box_right {
          position: absolute;
          right: 1rem;
          bottom: 0.4rem;
          width: 1rem;
          overflow: hidden;
          img {
          width: 100%;
          }
        }

        // .swiper_container_team_right {
        //     overflow: hidden;
        //     width: 1rem;
        //     position: absolute;
        //   right: 1rem;
        //   bottom: 0.4rem;
        //   width: 1rem;
        //     img {
        //       width: 100%;
        //       height: 100%;
        //     }
        //   }
      }
      .Product_content_H5 {
        display: none;
      }
    }

    //新闻资讯
    .Journalism {
      padding-top: 0.6rem;
      text-align: center;
      .Journalism_content {
        margin: 0 auto;
        margin-top: 0.37rem;
        .carousel {
          display: block;
          /deep/ .el-carousel__button {
            background-color: #fff;
            width: 20px;
          }
        }
        .carousel_h5 {
          display: none;
        }
        /deep/ .carousel {
          height: 3rem;
          .el-carousel__item {
            height: 3rem;
          }
        }
        /deep/ .el-carousel__container {
          height: 3rem;
        }
        .Journalism_content_chunk {
          overflow: hidden;
          .Journalism_content_chunk_left {
            float: left;
            width: 48%;
            height: 3rem;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .Journalism_content_chunk_right {
            float: left;
            width: 52%;
            padding: 0.6rem;
            height: 3rem;
            box-sizing: border-box;
            background-color: #f7f7f9;
            text-align: justify;
            h3 {
              font-size: 18px;
              font-weight: 900;
              color: #2d2d2d;
              // height: 0.4rem;
              line-height: 0.2rem;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2; //需要控制的文本行数
              overflow: hidden;
              margin-bottom: 0.15rem;
            }
            p {
              font-size: 14px;
              color: #333333;
              line-height: 0.2rem;
              height: 0.8rem;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 4; //需要控制的文本行数
              overflow: hidden;
            }
            .btn {
              width: 0.6rem;
              height: 0.24rem;
              background-color: #cc9966;
              font-size: 0.1rem;
              color: #ffffff;
              margin-top: 0.2rem;
            }
            /deep/ .el-button {
              padding: 0 !important;
            }
            .btn:hover {
              background-color: #0084ff;
            }
          }
        }
      }
    }
    //关于我们
    .About {
      margin: 0.6rem auto;
      display: flex;
      .AboutPc {
        display: flex;
      }
      .AboutH5 {
        display: none;
      }
      .About_left {
        width: 49%;
        margin-right: 2%;
        box-sizing: border-box;
        padding-right: 2.4%;
        padding-top: 0.625rem;
        .About_left_info {
          font-size: 14px;
          font-weight: 400;
          color: #222222;
          height: 0.8rem;
          margin: 0.16rem 0;
          text-align: justify;
          line-height: 0.16rem;
        }
        .About_left_info1 {
          display: flex;
          margin: 0.1rem 0;
          img {
            width: 35px;
            height: 40px;
          }
          p {
            padding: 0 20px;
            line-height: 0.22rem;
            font-size: 14px;
            color: #444;
          }
        }
      }
      .About_right {
        width: 33%;
        height: 3rem;
        background-color: #cc9966;
        position: relative;
        img {
          position: absolute;
          top: 0.5rem;
          height: 2rem;
          left: 17%;
          width: 132%;
        }
      }
    }
    //用户留言
    .Dialogue {
      width: 100%;
      background-image: url("../../assets/img/home/UserMessageBackground.jpg");
      overflow: hidden;
      .Dialogue_content {
        margin: 0.44rem auto;
        background-color: #fff;
        border-radius: 2px;
        box-sizing: border-box;
        padding: 0.41rem 0.574rem;
        .Dialogue_content_info {
          font-size: 20px;
          color: #666666;
          margin-top: 0.18rem;
          margin-bottom: 0.15rem;
        }
        .Dialogue_content_ipt {
          display: flex;
          div {
            background: #fff;
            border-radius: 5px;
            font-size: 14px;
            color: #222222;
            height: 0.3021rem;
            /deep/ .el-input-group__prepend {
              padding-right: 0;
            }
            /deep/ .el-input {
              input {
                height: 0.3021rem;
                background: #f2f2f2;
                border: 0px;
                padding: 0;
              }
              .el-input-group__prepend {
                background: #f2f2f2;
                border: 0px;
              }
              .el-input-group__append {
                background: #fff;
                border: 0px;
              }
            }
          }
          div:nth-child(2) {
            margin-left: 0.17rem;
            margin-right: 0.17rem;
          }
        }
        .Dialogue_content_txt {
          margin-top: 0.17rem;
          /deep/ .el-textarea {
            textarea {
              background: #f7f7f7;
              border: 0px;
              resize: none;
              padding: 0.1rem;
              font-size: 0.1rem;
            }
          }
        }
        .submit {
          width: 0.6rem;
          height: 0.24rem;
          background-color: #cc9966;
          font-size: 0.1rem;
          color: #ffffff;
          margin-top: 0.2rem;
        }
        /deep/ .el-button {
          padding: 0 !important;
        }
        .submit:hover {
          background-color: #cc9966;
        }
      }
    }
    //联系我们
    .relation {
      text-align: center;
      padding-top: 0.57rem;
      background-image: url("../../assets/img/home/ContactUsBackground.jpg");
      width: 100%;
      box-sizing: border-box;
      .relation_content {
        margin: 0.47rem auto 0;
        padding-bottom: 0.47rem;
        display: flex;
        .relation_content_lift {
          display: flex;
          flex-wrap: wrap;
          width: 80%;
          .relation_content_chunk {
            width: 50%;
            height: 0.625rem;
            display: flex;
            margin-bottom: 0.1rem;
            .relation_content_chunk_circle {
              width: 0.5rem;
              height: 0.5rem;
              background-color: #cc9966;
              border-radius: 50%;
              line-height: 0.5rem;
              img {
                width: 0.25rem;
                height: 0.25rem;
              }
            }
            .relation_content_chunk_info {
              flex: 1;
              padding: 0.05rem 0.1rem;
              text-align: left;
              h4 {
                font-size: 18px;
                color: #333333;
                padding: 0.05rem 0;
                font-weight: 900;
              }
              p {
                font-size: 14px;
                color: #333333;
                font-weight: 400;
              }
            }
          }
        }
        .QRCode {
          width: 1.1rem;
          height: 1.1rem;
          margin-top: 0.05rem;
          margin-left: 0.2rem;
        }
      }
    }
  }
  @media all and (min-width: 768px) and (max-width: 1024px) {
    .fixationProduct {
      width: 85%;
    }
    .Navigation_Home {
      display: none;
    }
    .Navigation_Home_H5 {
      display: block;
      .Navigation_Home_H5_content {
        background-image: url("../../assets/img/h5/Home/HomePageBackground.jpg");
        height: 13.3333rem;
        width: 100%;
        text-align: center;
        background-size: 100% 100%;
        position: relative;
      }
    }
    //   导航介绍
    .Navigation_Home_info {
      color: #fff;
      position: absolute;
      bottom: 20%;
      left: 50%;
      margin-left: -1.42rem;
      .btn {
        width: 2.84rem;
        height: 0.71rem;
        border: 2px solid #ffffff;
        background-color: transparent;
        font-size: 0.3rem;
        color: #fff;
        padding: 0.1rem;
      }
    }

    .title {
      position: relative;
      h1 {
        font-size: 0.35rem;
        font-weight: bold;
        color: #000000;
        margin-bottom: 0.17rem;
      }
      p {
        font-size: 0.24rem;
        color: #000000;
        opacity: 0.5;
        letter-spacing: 1px;
      }
    }

    //产品分类
    .Product {
      padding: 0.8rem 0 0.3rem 0;
      text-align: center;
      background-size: 100% 100%;
      .Product_content {
        margin: 0 auto;
        margin-top: 0.37rem;
        display: flex;
        .Product_content_chunk {
          width: 3.6rem;
          background: #fafafa;
          text-align: left;
          margin-right: 0.2rem;
          img {
            width: 100%;
            height: 1.8rem;
          }
          div {
            padding: 0.3rem 0.4rem;
            h2 {
              color: #2d2d2d;
              font-size: 0.26rem;
              font-weight: bold;
              margin-bottom: 0.14rem;
            }
            p {
              font-size: 0.15rem;
              color: #888888;
              font-weight: 500;
              line-height: 0.17rem;
              text-align: justify;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3; //需要控制的文本行数
              overflow: hidden;
              margin-bottom: 0.14rem;
              height: 0.49rem;
            }
            button {
              background-color: transparent;
              font-size: 0.15rem;
            }
            .ProductTitle {
              color: #cc9966;
            }
          }
        }
      }
      .Product_content_H5 {
        display: none;
      }
    }

    //新闻资讯
    .Journalism {
      padding-top: 0.35rem;
      text-align: center;
      .Journalism_content {
        margin-top: 0.37rem;
        .carousel {
          display: none;
        }
        .carousel_h5 {
          display: block;
          .carousel_h5_Swiper {
            padding: 0.1rem 0.2rem;
            .carousel_h5_content_chunk {
              display: flex;
              margin-bottom: 0.2rem;
              img {
                width: 1.6rem;
                height: 1.3rem;
              }
              div {
                flex: 1;
                padding-left: 0.2rem;
                text-align: left;
                padding-top: 0.1rem;
                h3 {
                  color: #2d2d2d;
                  font-size: 0.24rem;
                  font-weight: bold;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1; //需要控制的文本行数
                  overflow: hidden;
                  margin-bottom: 0.1rem;
                }
                p {
                  font-size: 0.2rem;
                  color: #888888;
                  font-weight: 500;
                  line-height: 0.22rem;
                  text-align: justify;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 3; //需要控制的文本行数
                  overflow: hidden;
                  margin-bottom: 0.14rem;
                  height: 0.64rem;
                }
              }
            }
          }
        }
      }
    }

    //关于我们
    .About {
      margin: 1rem auto 0.7rem;
      display: block;
      text-align: center;
      .AboutPc {
        display: none;
      }
      .AboutH5 {
        display: block;
        .About_top {
          position: relative;
          margin-bottom: 0.3rem;
          div {
            background-color: #CC9966;
            width: 40%;
            height: 4.54rem;
          }
          img {
            position: absolute;
            top: 50%;
            margin-top: -1.715rem;
            width: 4.59rem;
            height: 3.43rem;
            right: 1.2rem;
          }
        }
        .About_bottom {
          padding: 0.3rem;
          line-height: 0.4rem;
          font-size: 0.24rem;
          text-align: left;
          text-align: justify;
          .About_botton_info {
            margin-top: 0.4rem;
            display: flex;
            text-align: center;
            p {
              margin-top: 0.2rem;
              color: #777;
            }
          }
        }
      }
    }
    //用户留言

    .Dialogue {
      width: 100%;
      background-image: url("../../assets/img/home/UserMessageBackground.jpg");
      background-size: 100% 100%;
      overflow: hidden;
      .Dialogue_content {
        margin: 0.83rem auto;
        background-color: #fff;
        border-radius: 2px;
        box-sizing: border-box;
        padding: 0.41rem 0.574rem;
        .Dialogue_content_info {
          font-size: 0.24rem;
          color: #666666;
          margin: 0.4rem 0;
        }
        .Dialogue_content_ipt {
          display: block;
          div {
            background: #fff;
            border-radius: 5px;
            font-size: 0.28rem;
            color: #222222;
            height: 0.6rem;
            /deep/ .el-input {
              input {
                height: 0.6rem;
                background: #f2f2f2;
                border: 0px;
                padding: 0;
                font-size: 0.24rem;
              }
              .el-input-group__prepend {
                background: #f2f2f2;
                border: 0px;
                font-size: 0.24rem;
              }
              .el-input-group__append {
                background: #fff;
                border: 0px;
              }
            }
          }
          div:nth-child(2) {
            margin: 0.2rem 0;
          }
        }
        .Dialogue_content_txt {
          margin-top: 0.2rem;
          /deep/ .el-textarea {
            textarea {
              background: #f2f2f2;
              border: 0px;
              resize: none;
              padding: 0.1rem;
              font-size: 0.24rem;
              height: 2.95rem !important;
            }
          }
        }
        .submit {
          width: 1.7rem;
          height: 0.7rem;
          background-color: #cc9966;
          font-size: 0.26rem;
          color: #ffffff;
          margin: 0.5rem auto;
          display: block;
        }
        .submit:hover {
          background-color: #0084ff;
        }
      }
    }

    //联系我们
    .relation {
      text-align: center;
      padding-top: 1rem;
      background-image: url("../../assets/img/home/ContactUsBackground.jpg");
      width: 100%;
      background-size: 100% 100%;
      box-sizing: border-box;
      .relation_content {
        margin: 0.47rem auto 0;
        padding-bottom: 0.47rem;
        display: block;
        .relation_content_lift {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          .relation_content_chunk {
            width: 50%;
            height: 2.6rem;
            margin-bottom: 0.32rem;
            display: block;
            .relation_content_chunk_circle {
              width: 1.14rem;
              height: 1.14rem;
              background-color: #cc9966;
              border-radius: 50%;
              line-height: 1.14rem;
              img {
                width: 0.6rem;
                height: 0.6rem;
              }
              margin: 0 auto;
            }
            .relation_content_chunk_info {
              flex: 1;
              padding: 0.1rem;
              text-align: left;
              text-align: center;
              h4 {
                font-size: 0.26rem;
                color: #333333;
                padding: 0.05rem 0;
                font-weight: 900;
                line-height: 0.6rem;
              }
              p {
                font-size: 0.24rem;
                color: #333333;
                font-weight: 400;
              }
            }
          }
        }
        .QRCode {
          width: 2.5rem;
          height: 2.5rem;
          margin-top: 0.1rem;
          margin-left: 0.2rem;
        }
      }
    }
  }
  @media screen and (min-width: 320px) and (max-width: 750px) {
    .fixationProduct {
      width: 100%;
    }
    .Navigation_Home {
      display: none;
    }
    .Navigation_Home_H5 {
      margin-top: 0.7rem;
      display: block;
      .Navigation_Home_H5_content {
        background-image: url("../../assets/img/h5/Home/HomePageBackground.jpg");
        height: 13.3333rem;
        width: 100%;
        text-align: center;
        background-size: 100% 100%;
        position: relative;
      }
    }
    //   导航介绍
    .Navigation_Home_info {
      color: #fff;
      position: absolute;
      bottom: 20%;
      left: 50%;
      margin-left: -1.42rem;
      .btn {
        width: 2.84rem;
        height: 0.71rem;
        border: 2px solid #ffffff;
        background-color: transparent;
        font-size: 0.3rem;
        color: #fff;
        padding: 0.1rem;
      }
    }

    //产品分类
    .title {
      position: relative;
      h1 {
        font-size: 0.5rem;
        font-weight: bold;
        color: #000000;
        margin-bottom: 0.23rem;
      }
      p {
        font-size: 0.26rem;
        color: #000000;
        opacity: 0.5;
        letter-spacing: 1px;
        margin-bottom: 0.2rem;
      }
    }
    //产品分类
    .Product {
      padding-top: 1.1rem;
      text-align: center;
      background-size: 100% 100%;
      padding-bottom: 0.6rem;
      .Product_content {
        display: none;
      }
      .Product_content_H5 {
        display: block;
        .swiper-container_h5 {
          overflow: hidden;
        }
        .Product_content_chunk {
          width: 6.5rem;
          margin-right: 0.2rem;
          margin-top: 0.3rem;
          background-color: #f7f7f9;

          img {
            width: 100%;
            height: 4.2rem;
          }
          div {
            padding: 0.36rem;
            h2 {
              color: #2d2d2d;
              font-size: 0.36rem;
              font-weight: bold;
              margin-bottom: 0.14rem;
              margin: 0.2rem 0;
            }
            p {
              font-size: 0.3rem;
              color: #888888;
              font-weight: 500;
              line-height: 0.4rem;
              text-align: justify;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2; //需要控制的文本行数
              overflow: hidden;
              margin-bottom: 0.14rem;
              height: 0.8rem;
            }
            button {
              background-color: transparent;
              font-size: 0.3rem;
              padding: 0.3rem;
              margin-top: 0.2rem;
            }
            .ProductTitle {
              color: #cc9966;
            }
          }
        }
        .swiper-slide-active {
          width: 5.9rem;
          padding: 0.21rem;
          margin-top: 0;
          box-shadow: 0px 0.03rem 0.1rem 0px rgba(0, 0, 0, 0.1);
        }
      }
    } //新闻资讯

    .Product2 {
      text-align: center;
      background-image: url("../../assets/img/home/h5/dl_bg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      padding-top: 1rem;
      .title {
        padding-bottom: 0.7rem;
      }
      .Product_content_H5 {
        display: block;
        .swiper-container_h5 {
          overflow: hidden;
        }
        .Product_content_chunk {
          width: 6.5rem;
          margin-right: 0.2rem;
          margin-top: 0.3rem;
          // background-color: #f7f7f9;
          // background-color: #ccc;

          img {
            width: 100%;
          }
        }
        .swiper-slide-active {
          width: 5.9rem;
          // padding: 0.21rem;
          margin-top: 0;
          box-shadow: 0px 0.03rem 0.1rem 0px rgba(0, 0, 0, 0.1);
        }
      }
    }
    .Journalism {
      padding-top: 1.1rem;
      text-align: center;
      .Journalism_content {
        margin-top: 0.37rem;
        .carousel {
          display: none;
        }
        .carousel_h5 {
          display: block;
          .carousel_h5_Swiper {
            padding: 0.1rem 0.48rem;
            .carousel_h5_content_chunk {
              display: flex;
              margin-bottom: 0.2rem;
              box-shadow: 0px 0.03rem 0.07rem 0px rgba(0, 0, 0, 0.1);
              text-align: left;
              img {
                border-radius: 5px 0 0 5px;
                width: 2.36rem;
                height: 2.14rem;
              }
              div {
                flex: 1;
                padding: 0.1rem 0.4rem;
                h3 {
                  font-size: 0.3rem;
                  line-height: 0.5rem;
                  font-weight: 600;
                  color: #222222;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                }
                p {
                  font-size: 0.24rem;
                  font-weight: 400;
                  color: #999999;
                  line-height: 1rem;
                }
              }
            }
          }
        }
      }
    }

    //关于我们
    .About {
      margin: 1.5rem auto;
      display: block;
      text-align: center;
      .AboutPc {
        display: none;
      }
      .AboutH5 {
        display: block;
        .About_top {
          position: relative;
          margin-bottom: 0.3rem;
          div {
            background-color: #CC9966;
            width: 40%;
            height: 4.54rem;
          }
          img {
            position: absolute;
            top: 50%;
            margin-top: -1.715rem;
            width: 4.59rem;
            height: 3.43rem;
            right: 1.2rem;
          }
        }
        .About_bottom {
          padding: 0.3rem;
          line-height: 0.4rem;
          font-size: 0.24rem;
          text-align: left;
          text-align: justify;
          .About_botton_info {
            margin-top: 0.4rem;
            display: flex;
            text-align: center;
            p {
              margin-top: 0.2rem;
              color: #777;
            }
          }
        }
      }
    }
    //用户留言

    .Dialogue {
      width: 100%;
      background-image: url("../../assets/img/home/UserMessageBackground.jpg");
      overflow: hidden;
      background-size: 100% 100%;
      .Dialogue_content {
        margin: 0.83rem auto;
        background-color: #fff;
        border-radius: 2px;
        box-sizing: border-box;
        padding: 0.41rem 0.574rem;
        .Dialogue_content_info {
          font-size: 0.32rem;
          color: #666666;
          margin-top: 0.65rem;
          margin-bottom: 0.52rem;
        }
        .Dialogue_content_ipt {
          display: block;
          div {
            background: #fff;
            border-radius: 5px;
            font-size: 0.28rem;
            color: #222222;
            height: 0.775rem;
            /deep/ .el-input {
              input {
                height: 0.775rem;
                background: #f2f2f2;
                border: 0px;
                padding: 0;
              }
              .el-input-group__prepend {
                background: #f2f2f2;
                border: 0px;
              }
              .el-input-group__append {
                background: #fff;
                border: 0px;
              }
            }
          }
          div:nth-child(2) {
            margin: 0.2rem 0;
          }
        }
        .Dialogue_content_txt {
          margin-top: 0.2rem;
          /deep/ .el-textarea {
            textarea {
              background: #f2f2f2;
              border: 0px;
              resize: none;
              padding: 0.1rem;
              font-size: 0.28rem;
              height: 2.95rem !important;
            }
          }
        }
        .submit {
          width: 1.7rem;
          height: 0.74rem;
          background-color: #cc9966;
          font-size: 0.26rem;
          color: #ffffff;
          margin: 0.5rem auto;
          display: block;
        }
        .submit:hover {
          background-color: #0084ff;
        }
      }
    }

    //联系我们
    .relation {
      text-align: center;
      padding-top: 1rem;
      background-image: url("../../assets/img/home/ContactUsBackground.jpg");
      width: 100%;
      background-size: 100% 100%;
      box-sizing: border-box;

      .relation_content {
        margin: 0.47rem auto 0;
        padding-bottom: 0.47rem;
        display: block;
        .relation_content_lift {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          .relation_content_chunk {
            width: 50%;
            height: 2.6rem;
            margin-bottom: 0.32rem;
            display: block;
            .relation_content_chunk_circle {
              width: 1.14rem;
              height: 1.14rem;
              background-color: #cc9966;
              border-radius: 50%;
              line-height: 1.14rem;
              img {
                width: 0.6rem;
                height: 0.6rem;
              }
              margin: 0 auto;
            }
            .relation_content_chunk_info {
              flex: 1;
              padding: 0.1rem;
              text-align: left;
              text-align: center;
              h4 {
                font-size: 0.28rem;
                color: #333333;
                padding: 0.05rem 0;
                font-weight: 900;
                line-height: 0.6rem;
              }
              p {
                font-size: 0.28rem;
                color: #333333;
                font-weight: 400;
              }
            }
          }
        }
        .QRCode {
          width: 2.5rem;
          height: 2.5rem;
          margin-top: 0.1rem;
          margin-left: 0.2rem;
        }
      }
    }
  }
}
</style>
